document.addEventListener("DOMContentLoaded", () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Element with class "hero-full" is visible
        const navMitglieder = document.getElementById("nav-mitglieder");
        if (navMitglieder) {
          navMitglieder.style.borderColor = "white";
          navMitglieder.style.color = "white";
        }

        const docSearchIcon = document.querySelector(".DocSearch-Search-Icon");
        if (docSearchIcon) {
          docSearchIcon.style.color = "white";
        }
      } else {
        // Element with class "hero-full" is not visible
        const navMitglieder = document.getElementById("nav-mitglieder");
        if (navMitglieder) {
          navMitglieder.style.borderColor = "";
          navMitglieder.style.color = "";
        }

        const docSearchIcon = document.querySelector(".DocSearch-Search-Icon");
        if (docSearchIcon) {
          docSearchIcon.style.color = "";
        }
      }
    });
  });

  const heroFull = document.querySelector(".hero-full");
  if (heroFull) {
    observer.observe(heroFull);
  }
});
