export function adjustMainSectionMargin(): void {
  // Check if the first section contains a child with class "hero-full"
  const firstSection = document.querySelector("section");
  const hasHeroFull = firstSection?.querySelector(".hero-full") !== null;

  // Move the main section up if hero-full is present
  if (hasHeroFull) {
    const mainSection = document.querySelector("main");
    if (mainSection) {
      mainSection.style.marginTop = "-7rem";
    }
  }
}
